import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
Vue.prototype.matchup_cn_web=process.env.VUE_APP_MATCHUPEXPO_CN_WEB
Vue.prototype.matchup_en_web=process.env.VUE_APP_MATCHUPEXPO_EN_WEB



Vue.prototype.$http = axios
Vue.use(Antd)

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
