import Vue from "vue";
import _import from "@/utils/import"
import VueRouter from "vue-router";
const Seo = _import('seo/index')
// const En = _import('seo/en_index')
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "",
    component: Seo,
  },
  { path: '*', name: '404', component: _import('error/404') }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
