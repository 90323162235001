import Vue from "vue";
import Vuex from "vuex";
import { directSignUp, buyerloginBypass, buyerloginBycode } from "@/api/user";
import {
  getToken,
  setToken,
  setUserId,
  getUserId,
  getUserType,
  setUserType,
  getUserState,
  setUserState,
  setSysIm,
  getSysIm, getRole, setRole, setRight, getRight,
} from '@/utils/auth'
import storage from "../utils/storage";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    role: getRole() || 1,
    right: getRight() || 0,
    buyerUserInfo: storage.get('buyerUserInfo') || {},
    token: getToken(),
    userId: getUserId(),
    userType: getUserType(),//0 供应商 1 采购商
    userState: getUserState(),
    sysIm: getSysIm(),
  },
  mutations: {
    toggleRole(state, val) {
      setRole(val)
      state.role = val
    },
    toggleRight(state, val) {
      setRight(val)
      state.right = val
    },
    setBuyerUserInfo(state, val) {
      storage.set('buyerUserInfo', val)
      state.buyerUserInfo = val
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER_ID: (state, id) => {
      state.userId = id
    },
    /**
    *methodName:当前用户im信息
    *params:{userID}
    */
    SET_SYSIM: (state, sysIm) => {
      setSysIm(sysIm)
    },
    SET_USER_TYPE: (state, userType) => {
      state.userType = userType
    },
    SET_USERSTATE: (state, userState) => {
      state.userState = userState
    },
  },
  actions: {
    userRegister({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        directSignUp(userInfo).then(res => {
          const { data } = res
          // if (data.sysIm) {
          //   ImLogin(data.sysIm.imaccount,data.token)
          // }
          commit('setBuyerUserInfo', { sex: data.sex, name: data.lastName, role: data.role })
          commit('toggleRole', data.role)
          commit('toggleRight', data.isRight)
          commit('SET_TOKEN', data.token)
          commit('SET_USER_ID', data.id)
          commit('SET_SYSIM', data.sysIm)
          setToken(data.token)
          setUserId(data.id)
          setUserType(data.userType)
          commit('SET_USER_TYPE', data.userType)
          commit('SET_USERSTATE', data.userState)
          setUserState(data.userState)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    buyerloginBypass({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        buyerloginBypass(userInfo).then(res => {
          const { data } = res
          // if (data.sysIm) {
          //   ImLogin(data.sysIm.imaccount,data.token)
          // }
          commit('setBuyerUserInfo', { sex: data.sex, name: data.lastName, role: data.role })
          commit('toggleRole', data.role)
          commit('toggleRight', data.isRight)
          commit('SET_TOKEN', data.token)
          commit('SET_USER_ID', data.id)
          commit('SET_SYSIM', data.sysIm)
          setToken(data.token)
          setUserId(data.id)
          setUserType(data.userType)
          commit('SET_USER_TYPE', data.userType)
          commit('SET_USERSTATE', data.userState)
          setUserState(data.userState)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    buyerloginBycode({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        buyerloginBycode(userInfo).then(res => {
          const { data } = res
          // if (data.sysIm) {
          //   ImLogin(data.sysIm.imaccount,data.token)
          // }
          commit('setBuyerUserInfo', { sex: data.sex, name: data.lastName, role: data.role })
          commit('toggleRole', data.role)
          commit('toggleRight', data.isRight)
          commit('SET_TOKEN', data.token)
          commit('SET_USER_ID', data.id)
          commit('SET_SYSIM', data.sysIm)
          commit('toggleRight', data.isRight)
          setToken(data.token)
          setUserId(data.id)
          setUserType(data.userType)
          commit('SET_USER_TYPE', data.userType)
          commit('SET_USERSTATE', data.userState)
          setUserState(data.userState)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  // modules: {
  //   conversation
  // },
  getters: {
    token(state) {
      return state.token
    },
    getId(state) {
      return state.userId
    },
    getUserType(state) {
      return state.userType
    },
    getUserState(state) {
      return state.userState
    },
    getRole: state => state.role,
    getRight: state => state.right,
  },
});
