import request from "@/utils/request";
import Qs from 'qs'
// import $store from '@/store'
// 报名注册
export function directSignUp(data) {
  return request({
    url: "/platform/exhibitionsignup/directSignUp",
    method: "POST",
    data,
  });
}
// 获取验证码
export function loginSendPhoneVerifyPC(data) {
  return request({
      url: '/platform/platformUserLogin/loginSendPhoneVerifyPC',
      method: 'post',
      data: Qs.stringify(data)
  })
}
// 密码登录
export function buyerloginBypass(data) {
  return request({
      url: '/platform/platformUserLogin/loginEn',
      method: 'post',
      data: Qs.stringify(data)
  })
}
// 验证码登录
export function buyerloginBycode(data) {
  return request({
      url: '/platform/platformUserLogin/loginEnCode',
      method: 'post',
      data: Qs.stringify(data)
  })
}
// 来源编码是否存在
export function checkSourceCode(data) {
  return request({
      url: '/platform/exhibitionsignup/checkSourceCode',
      method: 'get',
      params:data
  })
}
