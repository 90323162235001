import Cookies from 'js-cookie'
const TokenKey = 'token'
const UserId = 'userId'
const ExhibitionId = 'exhiId'
const CompanyId = 'companyId'
const UserType = 'userType'
const UserState = 'userState'
const Locale = 'locale'
const sysIm = 'sysIm'
const LangArea = 'langArea'
const Role = 'Role'
const Right = 'Right'
const Channel = 'Channel'
export function setChannel(channel) {
  return Cookies.set(Channel, channel, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
export function getChannel() {
  return Cookies.get(Channel)
}
export function getRight() {
  return Cookies.get(Right)
}
export function setRight(right) {
  return Cookies.set(Right, right, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
export function getRole() {
  return Cookies.get(Role)
}
export function setRole(role) {
  return Cookies.set(Role, role, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}

export function removeToken() {
  return Cookies.remove(TokenKey, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
export function removeUserId() {
  return Cookies.remove(UserId, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
export function getUserId() {
  return Cookies.get(UserId)
}

export function setUserId(Id) {
  return Cookies.set(UserId, Id, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
export function getCompanyId() {
  return Cookies.get(CompanyId)
}

export function setCompanyId(Id) {
  return Cookies.set(CompanyId, Id, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}

// 设置获取展会Id
export function getExhiId() {
  return Cookies.get(ExhibitionId)
}

export function setExhiId(Id) {
  return Cookies.set(ExhibitionId, Id, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
//用户类型
export function getUserType() {
  return Cookies.get(UserType)
}

export function setUserType(type) {
  return Cookies.set(UserType, type, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
export function removeUserType() {
  return Cookies.remove(UserType, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
// 设置获取用户状态
export function setUserState(id) {
  return Cookies.set(UserState, id, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}

export function getUserState() {
  return Cookies.get(UserState)
}
export function removeUserState() {
  return Cookies.remove(UserState, { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}

// 设置语言cookie
export function seten() {
  return Cookies.set(Locale, 'en-US', { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}

// 设置

export function setSysIm(val) {
  return Cookies.set(sysIm, JSON.stringify(val), { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}



export function getSysIm() {
  return Cookies.get(sysIm)
}
//国家+语言
export function getLangArea() {
  const value = Cookies.get(LangArea) || ''
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }

}

export function setLangArea(value) {
  return Cookies.set(LangArea, JSON.stringify(value), { domain: process.env.VUE_APP_COOKIE_DOMAIN })
}
